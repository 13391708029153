var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"people-table",attrs:{"headers":_vm.headers,"items":_vm.$store.getters['people/items'],"items-per-page":_vm.per_page,"page":_vm.page,"loading":_vm.$store.getters['people/loading'].fetchAll,"server-items-length":_vm.$store.getters['people/total'],"footer-props":_vm.$config.vDataTable.footerProps,"disable-sort":""},on:{"update:itemsPerPage":function($event){_vm.per_page=$event},"update:items-per-page":[function($event){_vm.per_page=$event},_vm.fetchData],"update:page":[function($event){_vm.page=$event},_vm.fetchData]},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('img',{attrs:{"src":_vm.getPersonImage(item),"alt":"Image"}})])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$fullName(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true),model:{value:(_vm.menu[item.id]),callback:function ($$v) {_vm.$set(_vm.menu, item.id, $$v)},expression:"menu[item.id]"}},[_c('v-card',{attrs:{"width":"150"}},[_c('PeopleDialog',{attrs:{"person":item,"buttonText":_vm.$t('buttons.edit'),"button-icon":"mdi-pencil-outline","button-attrs":{
            class: 'btn-justify-left',
            block: true,
            color: 'transparent',
          }},on:{"click":function($event){_vm.menu[item.id] = false}}}),_c('confirm-dialog',{attrs:{"button-text":_vm.$t('buttons.delete'),"button-icon":"mdi-trash-can-outline","button-attrs":{
            class: 'btn-justify-left',
            block: true,
            color: 'transparent',
            loading: _vm.$store.getters['people/loading'].delete === item.id,
          }},on:{"confirm":function($event){return _vm.deletePerson(item)}}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }